import Login from "./components/Login";
import Home from "./components/Home";
import Barriers from "./components/Barriers";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/barriers',
        element: <Barriers />
    }
];

export default AppRoutes;

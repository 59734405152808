import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import LogoWrap from "../assets/images/logo-wrap.png";
import LogOut from "../assets/images/logout.png";
import HomeImage from "../assets/images/home.png";
import SlideImage from "../assets/images/slide-img.png"
import ImageIconTwoGreen from "../assets/images/green-icon.png";
import DottedSpinner from "../DottedSpinner"
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Barriers = () => {

    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [classToggleBarrier, setClassToggleBarrier] = useState('slide-btn-wrapper');
    const [barrierIds, setBarrierIds] = useState([]);
    const [activeBarrierId, setActiveBarrierId] = useState('');
    const [activeBarrier, setActiveBarrier] = useState('');

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            history("/login")
        }
        else {
            axios.get("/api/Barriers", {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "*/*",
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(response => {
                setBarrierIds(response.data)
            }).catch(error => {
                if (!error.response) {
                    history('/');
                } else {
                    let state_value = error.response.status
                    if (state_value === 401 || state_value === 500) {
                        history("/login")
                    }
                }
            }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeBarrierId])


    const handleToggleBarrier = () => {
        if (classToggleBarrier === 'slide-btn-wrapper' && activeBarrier.bstatus === 'close') {
            handleOpenBarrier();
            setClassToggleBarrier('slide-btn-wrapper slide-btn')

        }
        else {
            handleCloseBarrier();
            setClassToggleBarrier('slide-btn-wrapper')
        }
    }

    const handleLogout = () => {
        localStorage.removeItem("token")
        history("/login")
    }

    const handleOnBarrierClick = (d) => {
        const data = {
            id: d.id,
            bid: d.b_id,
            blocation: d.l_name,
            bstatus: d.status
        }
        if (d.status === 'open_permanently' || d.status === 'open') {
            setClassToggleBarrier('slide-btn-wrapper slide-btn')
        }
        else {
            setClassToggleBarrier('slide-btn-wrapper')
        }
        setActiveBarrierId(d.b_id)
        setActiveBarrier(data)
    }

    const handleOpenCloseBarrier = () => {
        const customAxios = axios.create({
            baseURL: process.env.REACT_APP_DEVMAN_BASE_URL
        });
        if (activeBarrier.bstatus === "close") {
            setIsLoading(true)
            customAxios.get(activeBarrier.blocation + "/set_gate_status/" + activeBarrier.bid + "?value=on", {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "*/*"
                }
            }).then(response => {
                console.log(response)
                const data_b = {
                    id: activeBarrier.id,
                    barrier_id: activeBarrier.bid,
                    location_name: activeBarrier.blocation,
                    status: 'open'
                }
                axios.put("/api/Barriers/" + activeBarrier.id, data_b, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: "*/*",
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }).then(response => {
                    console.log(response)
                    axios.get("/api/Barriers/" + activeBarrier.id, {
                        headers: {
                            'Content-Type': 'application/json',
                            Accept: "*/*",
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    }).then(response => {
                        console.log(response)
                        toast.success('Barrier ' + activeBarrier.bid + ' Opened Sucessfully!', {
                            position: "top-center",
                            autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: false, draggable: false, progress: undefined, theme: "colored",
                        })
                        if (response.data.status === "open") {
                            customAxios.get(activeBarrier.blocation + "/set_gate_status/" + activeBarrier.bid + "?value=off", {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Accept: "*/*"
                                }
                            }).then(response => {
                                console.log(response)
                                const data_b = {
                                    id: activeBarrier.id,
                                    barrier_id: activeBarrier.bid,
                                    location_name: activeBarrier.blocation,
                                    status: 'close'
                                }
                                axios.put("/api/Barriers/" + activeBarrier.id, data_b, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        Accept: "*/*",
                                        Authorization: `Bearer ${localStorage.getItem('token')}`
                                    }
                                }).then(response => {
                                    console.log(response)
                                    axios.get("/api/Barriers/" + activeBarrier.id, {
                                        headers: {
                                            'Content-Type': 'application/json',
                                            Accept: "*/*",
                                            Authorization: `Bearer ${localStorage.getItem('token')}`
                                        }
                                    }).then(response => {
                                        console.log(response)
                                        const data_get_res = {
                                            id: response.data.id,
                                            bid: response.data.barrier_id,
                                            blocation: response.data.location_name,
                                            bstatus: response.data.status
                                        }
                                        setActiveBarrierId(response.data.barrier_id)
                                        setActiveBarrier(data_get_res)
                                        toast.error('Barrier ' + activeBarrier.bid + ' Closed Sucessfully!', {
                                            position: "top-center",
                                            autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: false, draggable: false, progress: undefined, theme: "colored",
                                        })

                                        setIsLoading(false)
                                    }).catch(error => {
                                        console.log(error)
                                        setIsLoading(false)
                                    })
                                }).catch(error => {
                                    setIsLoading(false)
                                    if (!error.response) {
                                        history('/');
                                    } else {
                                        let state_value = error.response.status
                                        if (state_value === 401 || state_value === 500) {
                                            history("/login")
                                        }
                                    }
                                })
                            }).catch(error => {
                                setIsLoading(false)
                                console.log(error.response)
                            });
                        }
                    }).catch(error => {
                        console.log(error)
                        setIsLoading(false)
                    })
                }).catch(error => {
                    setIsLoading(false)
                    if (!error.response) {
                        history('/');
                    } else {
                        let state_value = error.response.status
                        if (state_value === 401 || state_value === 500) {
                            history("/login")
                        }
                    }
                })
            }).catch(error => {
                setIsLoading(false)
                console.log(error.response)
            });
        }
    }

    const handleOpenBarrier = () => {
        const customAxios = axios.create({
            baseURL: process.env.REACT_APP_DEVMAN_BASE_URL
        });
        setIsLoading(true)
        customAxios.get(activeBarrier.blocation + "/set_gate_status/" + activeBarrier.bid + "?value=on", {
            headers: {
                'Content-Type': 'application/json',
                Accept: "*/*"
            }
        }).then(response => {
            console.log(response)
            const data_b = {
                id: activeBarrier.id,
                barrier_id: activeBarrier.bid,
                location_name: activeBarrier.blocation,
                status: 'open_permanently'
            }
            axios.put("/api/Barriers/" + activeBarrier.id, data_b, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "*/*",
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(response => {
                console.log(response)
                axios.get("/api/Barriers/" + activeBarrier.id, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: "*/*",
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }).then(response => {
                    console.log(response)
                    toast.success('Barrier ' + activeBarrier.bid + ' Opened Sucessfully!', {
                        position: "top-center",
                        autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: false, draggable: false, progress: undefined, theme: "colored",
                    })
                    setIsLoading(false)
                }).catch(error => {
                    setIsLoading(false)
                    console.log(error.response)
                })
            }).catch(error => {
                setIsLoading(false)
                console.log(error.response)
            })
        }).catch(error => {
            setIsLoading(false)
            console.log(error.response)
        });
    }

    const handleCloseBarrier = () => {
        const customAxios = axios.create({
            baseURL: process.env.REACT_APP_DEVMAN_BASE_URL
        });
        setIsLoading(true)
        customAxios.get(activeBarrier.blocation + "/set_gate_status/" + activeBarrier.bid + "?value=off", {
            headers: {
                'Content-Type': 'application/json',
                Accept: "*/*"
            }
        }).then(response => {
            console.log(response)
            const data_b = {
                id: activeBarrier.id,
                barrier_id: activeBarrier.bid,
                location_name: activeBarrier.blocation,
                status: 'close'
            }
            axios.put("/api/Barriers/" + activeBarrier.id, data_b, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "*/*",
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(response => {
                console.log(response)
                axios.get("/api/Barriers/" + activeBarrier.id, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: "*/*",
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                }).then(response => {
                    console.log(response)
                    toast.error('Barrier ' + activeBarrier.bid + ' Closed Sucessfully!', {
                        position: "top-center",
                        autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: false, draggable: false, progress: undefined, theme: "colored",
                    })
                    setIsLoading(false)
                }).catch(error => {
                    setIsLoading(false)
                    console.log(error.response)
                })
            }).catch(error => {
                setIsLoading(false)
                console.log(error.response)
            })
        }).catch(error => {
            setIsLoading(false)
            console.log(error.response)
        });
    }

    return (

        <div className="inner-page-wrapper">
            <div className="container">
                <div className="inner-page-header-wrapper">
                    <div className="user-name-wrap">
                        <i className="fa fa-user"></i>
                        Hi {localStorage.getItem('username')} !
                    </div>
                    <div className="inner-page-logo">
                        <a href="/"><img src={LogoWrap} alt="img" /></a>
                    </div>
                    <div className="inner-header-right" onClick={handleLogout}>
                        <button className="link-btn"><img src={LogOut} alt="img" /> Log out</button>
                    </div>
                </div>
            </div>

            <div className="inner-page-data-hold">
                <div className="container">
                    <div className="inner-page-data-hold">
                        <div className="top-bar">
                            <div className="home-icon">
                                <div className="next-icon">
                                    <a href="/"> <img src={HomeImage} alt="img" /> </a>
                                </div>
                                <div className="next-icon">
                                    <a href="/barriers"> <img src={ImageIconTwoGreen} alt="img" /></a>
                                </div>
                            </div>
                            <div className="top-bar-center">
                                Schranke
                            </div>
                            <div className="top-search-wrap">
                                <i className="fa fa-search"></i>
                                <input type="text" id="input-search" placeholder="Suchen..." />
                            </div>
                        </div>

                        <div className="inner-page-table-wrap inner-page-bg-wrap">
                            <ToastContainer />
                            <div className="slide-btn-page-min-height">
                                <div className="modal-number-hold">
                                    {barrierIds.map((barriers) =>
                                        <span className={activeBarrierId === barriers.barrier_id ? 'active' : ''} key={barriers.id} onClick={() => handleOnBarrierClick({ id: barriers.id, b_id: barriers.barrier_id, l_name: barriers.location_name, status: barriers.status })}>{barriers.name}</span>
                                    )}
                                </div>
                                <div className={classToggleBarrier} >
                                    <div className="left-btn">
                                        <button className="btn coment-btn" onClick={() => handleOpenCloseBarrier()}>{isLoading ? <DottedSpinner /> : 'Auf'}</button>
                                    </div>
                                    <div className="slide-btn-right-sec">
                                        <div className="slide-btn-inner-wrap">
                                            <div className="slide-box">
                                                <button className="btn" onClick={handleToggleBarrier}>{isLoading ? <DottedSpinner /> : 'Dauerhaft auf'}</button>
                                                <img src={SlideImage} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Barriers;
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ImageCross from "../assets/images/cross.png";
import axios from 'axios';
import ImageComment from "../assets/images/commect.png";
import DottedSpinner from "../DottedSpinner"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ShowDetails = (props) => {
    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showAddImg, setShowAddImg] = useState(true);
    const [transaction, setTransaction] = useState('');
    const [imageData, setImageData] = useState([]);
    const [nullIndex, setNullIndex] = useState('');
    const isCaptureImgEnabled = process.env.REACT_APP_ENABLE_CAPTURE_IMG;
    const isEditDataEnabled = process.env.REACT_APP_ENABLE_EDIT_DATA;

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            history("/login")
        }
        else {
            setIsLoading(true)
            axios.get("/api/transact/" + props.transaction + "/", {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "*/*",
                    Authorization: `token ${localStorage.getItem('token')}`
                }
            }).then(response => {
                setTransaction(response.data)
                axios.get("api/SaveBase64/?transaction=" + response.data.id, {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: "*/*",
                        Authorization: `token ${localStorage.getItem('token')}`
                    }
                }).then(res => {
                    setIsLoading(false)
                    setImageData(res.data[0])
                    if (res.data[0].image7 === null) {
                        setNullIndex('image7')
                    }
                    else if (res.data[0].image8 === null) {
                        setNullIndex('image8')
                    }
                    else if (res.data[0].image9 === null) {
                        setNullIndex('image9')
                    }
                    else if (res.data[0].image10 === null) {
                        setNullIndex('image10')
                    }
                    else {
                        setNullIndex('all_full')
                    }
                }).catch(error => {
                    setIsLoading(false)
                    if (!error.response) {
                        history('/');
                    } else {
                        let state_value = error.response.status
                        if (state_value === 401 || state_value === 500) {
                            history("/login")
                        }
                    }
                }
                )
            }).catch(error => {
                setIsLoading(false)
                if (!error.response) {
                    history('/');
                } else {
                    let state_value = error.response.status
                    if (state_value === 401 || state_value === 500) {
                        history("/login")
                    }
                }
            }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.transaction, props.updateShowDetails])

    const handleClosePreview = () => {
        props.handleClosePreview(false)
    }

    const handleOpenAuf = () => {
        const data = {
            transaction_id: transaction.id,
            vehicle: transaction.vehicle != null ? transaction.vehicle : '',
            status: transaction.status != null ? transaction.status : '',
            open: true
        }
        props.handleOpenAuf(data)
    }

    const handleOpenImagePreview = (imageurl, open_image, open_camera, del, index) => {

        const data = {
            imageurl: imageurl,
            open_image: open_image,
            open_camera: open_camera,
            transaction_id: transaction.id,
            delete: del,
            index: index
        }
        props.handleOpenImagePreview(data)
    }

    return (
        <div className="tr-modal-wrap show-modal">
            <ToastContainer />
            {isLoading ? <div style={{ display: 'flex', fontWeight: 'bold' }} > <DottedSpinner />  Daten laden.. </div > :
                <>
                    <div className="tr-modal-top-wrap">
                        <div className="tr-modal-right">
                            {isEditDataEnabled === 'true' ?
                                <button className="coment-btn" data-toggle="modal" data-target="#Aufruf-preview-modal" onClick={handleOpenAuf}><img src={ImageComment} alt="img" /> Bearbeiten</button> : ''}
                            <button className="cross-btn"><img src={ImageCross} alt="img" onClick={handleClosePreview} /></button>
                        </div>

                        <div className="tr-modal-left">
                            <h3>Lfs Num: {transaction.lfd_nr}</h3>
                            <div className="tr-mdl-lfs-data">
                                {transaction.vehicle != null ? <span><strong>Fahrzeug:</strong>{transaction.vehicle}</span> : ''}
                                {transaction.forwarders != null ? <span><strong>Spedition:</strong>{transaction.forwarders} </span> : ''}
                                {transaction.customer != null ? <span><strong>Kunde:</strong>{transaction.customer} </span> : ''}
                                {transaction.project_number != null ? <span><strong>Projekt - Nr:</strong>{transaction.project_number} </span> : ''}
                                {transaction.article != null ? <span><strong>Material:</strong> {transaction.article}</span> : ''}
                                {transaction.first_weight != null ? <span><strong>Erstgewicht:</strong> {transaction.first_weight} kg</span> : ''}
                            </div>
                        </div>
                    </div>

                    <div className="tr-modal-images-section">
                        <div className="tr-modal-img-section-left">
                            {imageData != null ? imageData.image1 != null ?
                                < div className="upload-img-wrap" onClick={() => handleOpenImagePreview(imageData.image1, true, false, true, 'image1')}>
                                    <div className="image-preview" data-toggle="modal" data-target="#preview-modal">
                                        <img src={imageData.image1} alt="img" />
                                    </div>
                                </div> : '' : ''}

                            {imageData != null ? imageData.image2 != null ?
                                < div className="upload-img-wrap" onClick={() => handleOpenImagePreview(imageData.image2, true, false, true, 'image2')}>
                                    <div className="image-preview" data-toggle="modal" data-target="#preview-modal">
                                        <img src={imageData.image2} alt="img" />
                                    </div>
                                </div> : '' : ''}

                            {imageData != null ? imageData.image3 != null ?
                                < div className="upload-img-wrap" onClick={() => handleOpenImagePreview(imageData.image3, true, false, true, 'image3')}>
                                    <div className="image-preview" data-toggle="modal" data-target="#preview-modal">
                                        <img src={imageData.image3} alt="img" />
                                    </div>
                                </div> : '' : ''}
                            {imageData != null ? imageData.image4 != null ?
                                < div className="upload-img-wrap" onClick={() => handleOpenImagePreview(imageData.image4, true, false, true, 'image4')}>
                                    <div className="image-preview" data-toggle="modal" data-target="#preview-modal">
                                        <img src={imageData.image4} alt="img" />
                                    </div>
                                </div> : '' : ''}
                            {imageData != null ? imageData.image5 != null ?
                                < div className="upload-img-wrap" onClick={() => handleOpenImagePreview(imageData.image5, true, false, true, 'image5')}>
                                    <div className="image-preview" data-toggle="modal" data-target="#preview-modal">
                                        <img src={imageData.image5} alt="img" />
                                    </div>
                                </div> : '' : ''}
                            {imageData != null ? imageData.image6 != null ?
                                < div className="upload-img-wrap" onClick={() => handleOpenImagePreview(imageData.image6, true, false, true, 'image6')}>
                                    <div className="image-preview" data-toggle="modal" data-target="#preview-modal">
                                        <img src={imageData.image6} alt="img" />
                                    </div>
                                </div> : '' : ''}

                            {imageData != null ? imageData.image7 != null ?
                                < div className="upload-img-wrap" onClick={() => handleOpenImagePreview(imageData.image7, true, false, true, 'image7')}>
                                    <div className="image-preview" data-toggle="modal" data-target="#preview-modal">
                                        <img src={imageData.image7} alt="img" />
                                    </div>
                                </div> : '' : ''}

                            {imageData != null ? imageData.image8 != null ?
                                < div className="upload-img-wrap" onClick={() => handleOpenImagePreview(imageData.image8, true, false, true, 'image8')}>
                                    <div className="image-preview" data-toggle="modal" data-target="#preview-modal">
                                        <img src={imageData.image8} alt="img" />
                                    </div>
                                </div> : '' : ''}
                            {imageData != null ? imageData.image9 != null ?
                                < div className="upload-img-wrap" onClick={() => handleOpenImagePreview(imageData.image9, true, false, true, 'image9')}>
                                    <div className="image-preview" data-toggle="modal" data-target="#preview-modal">
                                        <img src={imageData.image9} alt="img" />
                                    </div>
                                </div> : '' : ''}
                            {imageData != null ? imageData.image10 != null ?
                                < div className="upload-img-wrap" onClick={() => handleOpenImagePreview(imageData.image10, true, false, true, 'image10')}>
                                    <div className="image-preview" data-toggle="modal" data-target="#preview-modal">
                                        <img src={imageData.image10} alt="img" />
                                    </div>
                                </div> : '' : ''}
                            {isCaptureImgEnabled === 'true' ?
                                nullIndex === 'all_full' ? '' : showAddImg ?
                                    <div className="upload-img-wrap" onClick={() => handleOpenImagePreview(nullIndex, false, true, false, nullIndex)}>
                                        <div className="input-file-wrap">
                                            <span>+</span>
                                            Bild
                                            <input />
                                        </div>
                                    </div> : '' : ''}
                        </div>
                        <div className="tr-modal-right tr-modal-img-section-right">
                            <button className="coment-btn" onClick={handleClosePreview} >Fertig</button>
                            <button className="coment-btn" onClick={handleClosePreview} >Abbrechen</button>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default ShowDetails;
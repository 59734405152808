import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ImageCross from "../assets/images/cross.png";
import ImageCheck from "../assets/images/check.png";
import ImageDelete from "../assets/images/delete.png";
import Webcam from "react-webcam";
import axios from 'axios';
import DottedSpinner from "../DottedSpinner"

const videoConstraints = {
    width: 1920,
    height: 1080,
    aspectRatio: 1,
    facingMode: "environment"
};

const ImagePreview = (props) => {
    const history = useNavigate();
    const [webCamVideoConstraints] = useState(videoConstraints);
    const [showDelete, setShowDelete] = useState(false);
    const webcamRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.data.delete) {
            setShowDelete(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const buildRequest = (base64, id) => {

        if (props.data.index === 'image7') {
            const d = {
                id: id,
                image7: base64
            }
            return d;
        }
        else if (props.data.index === 'image8') {
            const d = {
                id: id,
                image8: base64
            }
            return d;
        }
        else if (props.data.index === 'image9') {
            const d = {
                id: id,
                image9: base64
            }
            return d;
        }
        else if (props.data.index === 'image10') {
            const d = {
                id: id,
                image10: base64
            }
            return d;
        }
    }

    const handleCaptureAndSaveImage = () => {
        const imageSrc = webcamRef.current?.getScreenshot();

        if (localStorage.getItem("token") === null) {
            history("/login")
        }
        else {
            setIsLoading(true)
            axios.get("/api/SaveBase64/?transaction=" + props.data.transaction_id, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "*/*",
                    Authorization: `token ${localStorage.getItem('token')}`
                }
            }).then(response => {
                axios.put("/api/SaveBase64/" + response.data[0].id + "/", buildRequest(imageSrc.split(",")[1], response.data[0].transaction), {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: "*/*",
                        Authorization: `token ${localStorage.getItem('token')}`
                    }
                }).then(res => {
                    setIsLoading(false)
                    handleCloseImagePreview()
                }).catch(error => {
                    setIsLoading(false)
                    if (!error.response) {
                        history('/');
                    } else {
                        let state_value = error.response.status
                        if (state_value === 401) {
                            history("/login")
                        }
                    }
                    handleCloseImagePreview()
                }
                )
            }).catch(error => {
                setIsLoading(false)
                if (!error.response) {
                    history('/');
                } else {
                    let state_value = error.response.status
                    if (state_value === 401) {
                        history("/login")
                    }
                }
                handleCloseImagePreview()
            }
            )
        }
    }

    const handleDeleteImage = () => {

        if (localStorage.getItem("token") === null) {
            history("/login")
        }
        else {
            setIsLoading(true)
            axios.get("/api/SaveBase64/?transaction=" + props.data.transaction_id, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "*/*",
                    Authorization: `token ${localStorage.getItem('token')}`
                }
            }).then(response => {
                
                axios.put("/api/SaveBase64/" + response.data[0].id + "/", buildRequest(null, response.data[0].transaction), {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: "*/*",
                        Authorization: `token ${localStorage.getItem('token')}`
                    }
                }).then(res => {
                    setIsLoading(false)
                    
                    handleCloseImagePreview()
                }).catch(error => {
                    setIsLoading(false)
                    if (!error.response) {
                        history('/');
                    } else {
                        let state_value = error.response.status
                        if (state_value === 401 || state_value === 500) {
                            history("/login")
                        }
                    }
                }
                )
            }).catch(error => {
                setIsLoading(false)
                if (!error.response) {
                    history('/');
                } else {
                    let state_value = error.response.status
                    if (state_value === 401 || state_value === 500) {
                        history("/login")
                    }
                }
            }
            )
        }
    }


    const handleCloseImagePreview = () => {
        props.handleCloseImagePreview()
    }

    return (

        <div className="modal fade image-preview-modal show" id="preview-modal" tabindex="-1" role="dialog" aria-labelledby="preview-modal" style={{ display: 'block', background: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered" role="document">

                <div className="modal-content">
                    <div className="modal-body">
                        {isLoading ? <p style={{ display: 'flex', justifyContent: 'center', paddingTop: '100px', paddingBottom: '100px', fontSize: '24px' }} > <DottedSpinner /> <b> Bitte warten!</b> </p > : <>
                            <div className="img-pre-hold">
                                {props.data.open_camera ?
                                    <Webcam
                                        audio={false}
                                        height={400}
                                        ref={webcamRef}
                                        minScreenshotHeight="800"
                                        minScreenshotWidth="800"
                                        screenshotFormat="image/jpeg"
                                        width={400}
                                        videoConstraints={webCamVideoConstraints}
                                    />
                                    :
                                    <img src={props.data.imageurl} alt="img" />}
                            </div>

                            <div className="pre-image-action">
                                {props.data.open_camera === true ? <button type="button" data-dismiss="modal" aria-label="Close" onClick={handleCaptureAndSaveImage}>
                                    <img src={ImageCheck} alt="img" />
                                </button> : ''}

                                {showDelete ?
                                    <button type="button" className="delete-img" onClick={handleDeleteImage}>
                                        <img src={ImageDelete} alt="img" />
                                    </button> : ''}
                                <button type="button" data-dismiss="modal" aria-label="Close" onClick={handleCloseImagePreview}>
                                    <img src={ImageCross} alt="img" />
                                </button>
                            </div>
                        </>
                        }
                    </div>
                </div>
                }
            </div>
        </div>


    )
}

export default ImagePreview;
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import LogoWrap from "../assets/images/logo-wrap.png";
import LogOut from "../assets/images/logout.png";
import HomeImage from "../assets/images/home-green.png";
import ShowDetails from "../components/ShowDetails";
import DottedSpinner from "../DottedSpinner"
import ShowAufruf from './ShowAufruf';
import ImagePreview from './ImagePreview';

const Home = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [transactionId, setTransactionId] = useState('');
    const [aufrufData, setAufrufData] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [updateShowDetails, setUpdateShowDetails] = useState(false);
    const [showAufruf, setShowAufruf] = useState(false);
    const [showImagePreview, setShowImagePreview] = useState(false);
    const [imagePreviewData, setImagePreviewData] = useState('');
    const history = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("search") !== null) {
            localStorage.removeItem("search")
        }
        if (localStorage.getItem("token") === null) {
            history("/login")
        }
        else {
            setIsLoading(true)
            axios.get("/api/transact/?trans_flag=0", {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "*/*",
                    Authorization: `token ${localStorage.getItem('token')}`
                }
            }).then(response => {
                const status_0 = 'Anlieferung';
                const status_1 = 'Abholung';

                const hof_tran = response.data;

                const updatedTransactions = hof_tran.map((transaction) => {
                    if (transaction.status === '0') {
                        return { ...transaction, status: status_0 };
                    }
                    else if (transaction.status === '1') {
                        return { ...transaction, status: status_1 };
                    }
                    return transaction;
                });
                
                setIsLoading(false)
                setTransactions(updatedTransactions)
                setFilteredData(updatedTransactions)
            }).catch(error => {
                setIsLoading(false)
                if (!error.response) {
                    history('/');
                } else {
                    let state_value = error.response.status
                    if (state_value === 401 || state_value === 500) {
                        history("/login")
                    }
                }
            });

            const interval = setInterval(fetchData, 2000); // Fetch data every 2 seconds
            return () => {
                clearInterval(interval); // Clear the interval when the component unmounts
            };

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("token")])

    const formatDateTime = (dateTimeString) => {
        const dateObj = new Date(dateTimeString);
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear().toString().slice(-2);
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    const fetchData = () => {
        if (localStorage.getItem("search") === null) {

            axios.get("/api/transact/?trans_flag=0", {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "*/*",
                    Authorization: `token ${localStorage.getItem('token')}`
                }
            }).then(response => {
                const status_0 = 'Anlieferung';
                const status_1 = 'Abholung';

                const hof_tran = response.data;

                const updatedTransactions = hof_tran.map((transaction) => {
                    if (transaction.status === '0') {
                        return { ...transaction, status: status_0 };
                    }
                    else if (transaction.status === '1') {
                        return { ...transaction, status: status_1 };
                    }
                    return transaction;
                });

                setIsLoading(false)
                setTransactions(updatedTransactions)
                setFilteredData(updatedTransactions)
            }).catch(error => {
                if (!error.response) {
                    history('/');
                } else {
                    let state_value = error.response.status
                    if (state_value === 401 || state_value === 500) {
                        history("/login")
                    }
                }
            });
        }
    }

    const handleLogout = () => {
        localStorage.removeItem("token")
        history("/login")
    }

    const handleSearchChange = (data) => {
        
        localStorage.setItem("search", data)
        if (localStorage.getItem("search") !== null) {
            axios.get("/api/Transactions", {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "*/*",
                    Authorization: `token ${localStorage.getItem('token')}`
                }
            }).then(response => {
                const status_0 = 'Anlieferung';
                const status_1 = 'Abholung';

                const hof_tran = response.data;

                const updatedTransactions = hof_tran.map((transaction) => {
                    if (transaction.status === '0') {
                        return { ...transaction, status: status_0 };
                    }
                    else if (transaction.status === '1') {
                        return { ...transaction, status: status_1 };
                    }
                    return transaction;
                });

                setIsLoading(false)
                setTransactions(updatedTransactions)
            }).catch(error => {
                if (!error.response) {
                    history('/');
                } else {
                    let state_value = error.response.status
                    if (state_value === 401 || state_value === 500) {
                        history("/login")
                    }
                }
            });

            const deepSearch = (obj, query) => {
                for (let key in obj) {
                    const value = obj[key];
                    if (typeof value === 'object') {
                        if (deepSearch(value, query)) {
                            return true;
                        }
                    } else if (String(value).toLowerCase().includes(query.toLowerCase())) {
                        return true;
                    }
                }
                return false;
            };

            const filtered_data = transactions.filter((item) => deepSearch(item, data));
            setFilteredData(filtered_data)
        }
        else {
            localStorage.removeItem("search")
        }
    }

    const handleClosePreview = (data) => {
        if (!data) {
            setShowPreview(false)
            setTransactionId('')
        }
    }

    const handleCloseAuf = (data) => {
        if (!data) {
            if (updateShowDetails) {
                setUpdateShowDetails(false)
            }
            else {
                setUpdateShowDetails(true)
            }
            setShowAufruf(false)
        }
    }

    const handleCloseImagePreview = () => {
        if (updateShowDetails) {
            setUpdateShowDetails(false)
        }
        else {
            setUpdateShowDetails(true)
        }
        setShowImagePreview(false)
    }

    const handleOpenPreview = (id) => {
        setTransactionId(id)
        setShowPreview(true)
    }

    const handleOpenAuf = (data) => {
        if (data.open) {
            setShowAufruf(true)
            setAufrufData(data)
        }
    }

    const handleOpenImagePreview = (data) => {
        setImagePreviewData(data)
        setShowImagePreview(true)
    }

    return (
        <>
            <div className="inner-page-wrapper">
                <div className="container">
                    <div className="inner-page-header-wrapper">
                        <div className="user-name-wrap">
                            <i className="fa fa-user"></i>
                            Hi {localStorage.getItem('username')} !
                        </div>
                        <div className="inner-page-logo">
                            <a href="/"><img src={LogoWrap} alt="img" /></a>
                        </div>
                        <div className="inner-header-right" onClick={handleLogout}>
                            <button className="link-btn"><img src={LogOut} alt="img" />Ausloggen</button>
                        </div>
                    </div>
                </div>

                <div className="inner-page-data-hold">
                    <div className="container">
                        <div className="inner-page-data-hold">
                            <div className="top-bar">
                                <div className="home-icon">
                                    <img src={HomeImage} alt="img" />
                                </div>
                                <div className="top-bar-center">
                                    Hofliste
                                </div>
                                <div className="top-search-wrap">
                                    <i className="fa fa-search"></i>

                                    <input type="text" placeholder="Suchen..." onChange={(e) => handleSearchChange(e.target.value)} />
                                </div>
                            </div>
                            <div className="inner-page-table-wrap">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>KFZ-Kennzeichen</th>
                                            <th>Spedition</th>
                                            <th>Kunden</th>
                                            <th>Projekt-Nr</th>
                                            <th>Material</th>
                                            <th>Ladungstyp</th>
                                            <th>Einfahrtzeit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? <tr>
                                            <td colSpan="7">
                                                <div style={{ display: 'flex', fontWeight: 'bolder', fontSize: '20px' }}>
                                                    <DottedSpinner/> Daten laden..
                                                </div>
                                            </td>
                                        </tr> : filteredData.map((transaction, index) =>
                                            <tr key={transaction.id} className={transaction.id === transactionId ? "active" : ''} onClick={event => { handleOpenPreview(transaction.id) }}>
                                                <td>
                                                    <div className="td-wrap">{transaction.vehicle != null ? transaction.vehicle : ''}</div>
                                                </td>
                                                <td>
                                                    <div className="td-wrap">{transaction.forwarders != null ? transaction.forwarders : ''}</div>
                                                </td>
                                                <td>
                                                    <div className="td-wrap">{transaction.customer != null ? transaction.customer : ''}</div>
                                                </td>
                                                <td>
                                                    <div className="td-wrap">{transaction.project_number != null ? transaction.project_number : ''}</div>
                                                </td>
                                                <td>
                                                    <div className="td-wrap">{transaction.article != null ? transaction.article : ''}</div>
                                                </td>
                                                <td>
                                                    <div className="td-wrap">{transaction.status != null ? transaction.status : ''}</div>
                                                </td>
                                                <td>
                                                    <div className="td-wrap">{formatDateTime(transaction.created_date_time)}</div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {showPreview ? <ShowDetails handleClosePreview={handleClosePreview} transaction={transactionId} handleOpenAuf={handleOpenAuf} updateShowDetails={updateShowDetails} handleOpenImagePreview={handleOpenImagePreview} /> : ''}

                            </div>
                        </div>
                    </div>
                </div>
                {showAufruf ? <ShowAufruf data={aufrufData} handleCloseAuf={handleCloseAuf} /> : ''}
            </div>
            {showImagePreview ? <ImagePreview data={imagePreviewData} handleCloseImagePreview={handleCloseImagePreview} /> : ''}
        </>
    )
}

export default Home;
import React, { useState } from 'react';
import Image from "../assets/images/logo-wrap.png";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const login_state = {
    name: '',
    email: '',
    password: '',
    login_username_error: '',
    login_password_error: '',
    error: ''
}

const textStyle = {
    padding: '15px',
    fontSize: '24px',
    fontWeight: 'bolder',
    color: 'red'
}

const Login = () => {

    const [isLoading, setIsLoading] = useState(false);
    const history = useNavigate();
    const [loginObject, setLoginObject] = useState(login_state)

    const handleFormSubmit = (e) => {
        e.preventDefault()

        const login_parameter = {
            username: loginObject.email,
            password: loginObject.password,
        }

        if (loginObject.username === '') {
            alert("Bitte geben Sie den Benutzernamen ein.")
        } else if (loginObject.password === '') {
            alert("'Bitte geben Sie das Passwort ein.'")
        } else {
            setIsLoading(true)
            axios.post('/api-token-auth', login_parameter, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "*/*"
                }
            })
                .then(response => {
                    setIsLoading(false)
                    
                    if (response.data.is_superuser) {
                        localStorage.setItem("token", response.data.token)
                        localStorage.setItem("username", loginObject.email)
                        history("/")
                    }
                    else if (response.data.role === 'tabletuser') {
                        localStorage.setItem("token", response.data.token)
                        localStorage.setItem("username", loginObject.email)
                        history("/")
                    }
                    else {
                        setLoginObject({
                            ...loginObject, error: "Die angegebenen Zugangsdaten stimmen nicht."
                        })
                    }
                }).catch(error => {
                    setIsLoading(false)
                    setLoginObject({
                        ...loginObject, error: "Die angegebenen Zugangsdaten stimmen nicht."
                    })
                });
        }
    }

    return (

        <div className="login-page-wrapper">
            <div className="login-inner-data">
                <div className="login-header-bar" style={{ width: "fit-content" }} >
                    <div className="login-logo-wrap">
                        <img src={Image} alt="img" />
                    </div>
                </div>

                <div className="login-password-wrap">
                    <div className="pass-left-side">
                        <label>Benutzername</label>
                        <div className="password-input">
                            <input type="text" onChange={event => setLoginObject({
                                ...loginObject, email: event.target.value,
                                login_password_error: '', error: ''
                            })} placeholder="Benutzername" />
                        </div>
                    </div>

                    <div className="pass-left-side">
                        <label>Passwort</label>
                        <div className="password-input">
                            <input type="password" onChange={event => setLoginObject({
                                ...loginObject, password: event.target.value,
                                login_password_error: '', error: ''
                            })} placeholder="********" />
                        </div>
                    </div>
                    <div className="pass-right-end">
                        <button className={isLoading ? 'enter-button loading' : 'enter-button'} onClick={handleFormSubmit}>{isLoading ? '' : 'Anmelden'}</button>
                    </div>
                </div>

                {

                }
                {loginObject.error != null ? <p style={textStyle}>{loginObject.error}</p> : ''}

            </div>
        </div>
    )
}
export default Login;
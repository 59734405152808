import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import ImageCross from "../assets/images/cross.png"
import axios from 'axios';
import DottedSpinner from "../DottedSpinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ShowAufruf = (props) => {

    const history = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchable, setIsSearchable] = useState(true);
    const [transaction, setTransaction] = useState('');
    const [materials, setMaterials] = useState([])
    const [originalMaterialId, setOriginalMaterialId] = useState();
    const [selectedMaterialId, setSelectedMaterialId] = useState();
    const [customer, setCustomer] = useState([])
    const [selectedCustomerId, setSelectedCustomerId] = useState();
    const [originalCustomerId, setOriginalCustomerId] = useState();

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            history("/login")
        }
        else {
            setIsLoading(true)
            axios.get("/api/Transactions/" + props.data.transaction_id + "/", {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: "*/*",
                    Authorization: `token ${localStorage.getItem('token')}`
                }
            }).then(response => {
                setTransaction(response.data)
                handleMaterialChange({
                    label: '',
                    value: response.data.article
                })
                setOriginalMaterialId(response.data.article)
                handleCustomerChange({
                    label: '',
                    value: response.data.customer
                })
                setOriginalCustomerId(response.data.customer)

                axios.get("/api/article-View/", {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: "*/*",
                        Authorization: `token ${localStorage.getItem('token')}`
                    }
                }).then(response => {
                    setIsLoading(false)
                    setMaterials(response.data)
                }).catch(error => {
                    setIsLoading(false)
                    if (!error.response) {
                        history('/');
                    } else {
                        let state_value = error.response.status
                        if (state_value === 401 || state_value === 500) {
                            history("/login")
                        }
                    }
                })
                axios.get("/api/customer-View/", {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: "*/*",
                        Authorization: `token ${localStorage.getItem('token')}`
                    }
                }).then(response => {
                    setIsLoading(false)
                    setCustomer(response.data)
                }).catch(error => {
                    setIsLoading(false)
                    if (!error.response) {
                        history('/');
                    } else {
                        let state_value = error.response.status
                        if (state_value === 401 || state_value === 500) {
                            history("/login")
                        }
                    }
                })
            }).catch(error => {
                setIsLoading(false)
                if (!error.response) {
                    history('/');
                } else {
                    let state_value = error.response.status
                    if (state_value === 401 || state_value === 500) {
                        history("/login")
                    }
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.transaction])

    const handleCloseAuf = () => {
        props.handleCloseAuf(false)
    }

    const handleUpdateControl = (e) => {
        
        const tran_data = transaction.temp === null ? '' : transaction.temp
        let temp = tran_data;
        if (originalMaterialId !== selectedMaterialId.value && (!tran_data.includes('article')) &&
            (originalCustomerId !== selectedCustomerId.value) && (!tran_data.includes('customer')))
        {
            temp = 'article,customer';
        }
        else if (originalMaterialId !== selectedMaterialId.value) {
            if (!tran_data.includes('article')) {
                if (tran_data.includes('customer')) {
                    temp = 'customer,article';
                }
                else {
                    temp = 'article';
                }
            }
            else {
                if (originalCustomerId !== selectedCustomerId.value) {
                    if (!tran_data.includes('customer')) {
                        if (tran_data.includes('article')) {
                            temp = 'article,customer';
                        }
                        else {
                            temp = 'customer';
                        }
                    }
                }
            }
        }
        else if (originalCustomerId !== selectedCustomerId.value) {
            if (!tran_data.includes('customer')) {
                if (tran_data.includes('article')) {
                    temp = 'article,customer';
                }
                else {
                    temp = 'customer';
                }
            }
        }

        const post_data = {
            article: selectedMaterialId.value,
            customer: selectedCustomerId.value,
            temp: temp
        }
        axios.put("/api/Transactions/" + transaction.id + "/", post_data, {
            headers: {
                'Content-Type': 'application/json',
                Accept: "*/*",
                Authorization: `token ${localStorage.getItem('token')}`
            }
        }).then(res => {
            handleCloseAuf()
            toast.success("Daten erfolgreich gespeichert.", { position: toast.POSITION.TOP_RIGHT })
        }).catch(error => {
            let state_value = error.response.status
            if (state_value === 400) {
                toast.error(error.response.statusText, { position: toast.POSITION.TOP_RIGHT })
            }
        })
    }

    const handleMaterialChange = (selectedMat) => {
        axios.get("/api/article-View/" + selectedMat.value + "/", {
            headers: {
                'Content-Type': 'application/json',
                Accept: "*/*",
                Authorization: `token ${localStorage.getItem('token')}`
            }
        }).then(response => {
            setSelectedMaterialId({
                label: response.data.name,
                value: response.data.id
            })
        })
    }

    const handleCustomerChange = (selectedCus) => {
        axios.get("/api/customer-View/" + selectedCus.value + "/", {
            headers: {
                'Content-Type': 'application/json',
                Accept: "*/*",
                Authorization: `token ${localStorage.getItem('token')}`
            }
        }).then(response => {
            setSelectedCustomerId({
                label: response.data.name1,
                value: response.data.id
            })
        })
    }

    const optionsMaterial = materials.map((material, index) => {
        return {
            label: material.name,
            value: material.id
        }
    })

    const optionsCustomer = customer.map((customer, index) => {
        return {
            label: customer.name1,
            value: customer.id
        }
    })

    const handleMaterialSelect = () => {
        return (<>
            <div className="modal-number-hold">
                <Select
                    value={selectedMaterialId}
                    isSearchable={isSearchable}
                    onChange={handleMaterialChange}
                    options={optionsMaterial}
                    styles={{
                        indicatorSeparator: () => ({ display: "none" }),

                    }}
                />
            </div>
        </>)
    }

    const handleCustomerSelect = () => {
        return (<>
            <div className="modal-number-hold">
                <Select
                    value={selectedCustomerId}
                    isSearchable={isSearchable}
                    onChange={handleCustomerChange}
                    options={optionsCustomer}
                    styles={{
                        indicatorSeparator: () => ({ display: "none" }),
                    }}
                />
            </div>
        </>)
    }

    return (
        <div className="modal fade  Aufruf-preview-modal show" id="Aufruf-preview-modal" role="dialog" aria-labelledby="Aufruf-preview-modal" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">

                    <div className="modal-body">
                        <div className="tr-modal-top-wrap">
                            <div className="tr-modal-right" onClick={handleCloseAuf}>
                                <button className="cross-btn" data-dismiss="modal" aria-label="Close"><img src={ImageCross} alt="img" /></button>
                            </div>

                            <div className="tr-modal-left">
                                <h3>Lfs Num: {props.data.transaction_id}</h3>
                                <div className="tr-mdl-lfs-data">
                                    <span><strong>Fahrzeug:</strong>{props.data.vehicle}</span>
                                </div>
                            </div>
                        </div>

                        <div className="modal-number-wrap">
                            <h3>Material</h3>
                            {handleMaterialSelect()}
                        </div>

                        <div className="modal-number-wrap">
                            <h3>Kunden</h3>
                            {handleCustomerSelect()}
                        </div>

                        <div className="tr-modal-right tr-modal-img-section-right aufruf-modal-btn">
                            <button className="coment-btn" onClick={handleUpdateControl}>{isLoading ? <DottedSpinner /> : 'Speichern'}</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ShowAufruf;
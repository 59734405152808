import React from 'react';
import './stylesheets/DottedSpinner.css'; // We'll create this CSS file next.

const DottedSpinner = () => {
    return (
        <div className="rotating-dots-spinner">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    );
};

export default DottedSpinner;
